<template>
  <div class="home">
    <img src="../assets/logo.jpg" class="w-100 h-100 fixed-top">
    <div class="blur h-100 w-100 fixed-top">
      <div class="row flex-row-reverse justify-content-end align-items-start h-100 p-0 m-0">
        <div class="col-xl-4 p-0 bg-white h-100">
          <div class="container p-5">
            <div class="row pt-4">
              <div class="col-12">
                <h1>Login</h1>
                <form @submit="login">
                  <div class="form-group mb-3">
                    <label class="h5">Username</label>
                    <input type="text" class="form-control" placeholder="Enter username" v-model="profile.username">
                    <small class="form-text text-muted">Enter your username.</small>
                  </div>
                  <div class="form-group mb-3">
                    <label class="h5">Password</label>
                    <input type="password" class="form-control" placeholder="Password" v-model="profile.password">
                  </div>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input">
                    <small class="form-text text-muted">Remember me.</small>
                    <button type="submit" class="btn btn-primary float-end" >Login</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div class="col-8 h-100">
          <div class="row d-flex justify-content-center align-items-center h-100">
            <img src="../assets/egc.png" class="w-25">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import toast from 'toast/src/services/toast'
import {services} from '../service/user-api'
import router from "../router";
import store from "node-back-client/src/store/index";

export default {
  name: 'Home',
  async created() {
    try {
      let user = await services.user.GetLoginUser()
      if(user != null) {
        this.redirect(store.state.token);
      }
    }
    catch (e) {
      console.log(e);
    }
  },
  data() {
    return {
      profile:{
        username:"",
        password:""
      }
    }
  },
  methods: {
    redirect(token) {
      if(!token) {
        return ;
      }
      let {url,currentUrl} = this.$route.query;

      if(!url) {
        return router.push("/account");
      }

      if(url.indexOf("?") === -1)
        url += "?"
      else
        url += "&"

      window.location.href = url + `token=${token}&currentUrl=${currentUrl}`;

    },
    async login(e) {
      e.preventDefault();
      try {
        if (!this.profile.username) {
          let e = new Error();
          e.message = "Please input username.";
          throw e;
        }

        if (!this.profile.password) {
          let e = new Error();
          e.message = "Please input password."
          throw e;
        }

        let user = await services.user.Login(this.profile);


        this.redirect(user.token);
      }
      catch (e) {
        console.log(e);
        toast.Notify({
          title: "Unable to login",
          message: e.message
        });
      }
    }
  }
}
</script>

<style scoped>
  @keyframes blur {
    0% {
      backdrop-filter: blur(0px);
    }
    100% {
      backdrop-filter: blur(10px);
    }
  }

  .blur {
    animation: blur 30s forwards;
  }
</style>

